import React from 'react';
import PhoneInput from 'react-phone-number-input/input';
import { twMerge } from 'tailwind-merge';
import { Field } from 'formik';

const NewPhoneInput = ({
  label,
  name,
  required,
  customLabel,
  labelClassName,
  inputClassName,
  className,
  errorText,
  disabled,
  ...restProps
}) => {
  return (
    <Field name={name}>
      {({ field, form: { touched, errors }, meta }) => (
        <div>
          {customLabel ? (
            customLabel
          ) : (
            <label
              htmlFor={name}
              className={twMerge(
                "block text-sm font-medium leading-6 text-gray-900 dark:text-white",
                labelClassName
              )}
            >
              {label}
            </label>
          )}
          <div className={twMerge("mt-2", className)}>
            <PhoneInput
              country="US"
              value={field.value}
              onChange={(newVal) => field.onChange({ target: { name, value: newVal } })}
              onBlur={field.onBlur}
              className={twMerge(
                "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset",
                "ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 focus:ring-0",
                "dark:bg-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-400",
                inputClassName,
                errors[name] ? "ring-red-500" : ""
              )}
              key={name}
              disabled={disabled}
              {...restProps}
            />
            {touched && errors[name] ? (
              <div className="text-xs text-red-600 mt-1">{errors[name]}</div>
            ) : null}
          </div>
        </div>
      )}
    </Field>
  );
};

export default NewPhoneInput;