import React from "react";
import ReportStatus from "./ReportStatus";
import { useMapRender } from "../../hooks/map/useMapRender";

const TractInfo = ({ data }) => {
  const { renderMap } = useMapRender({
    tracts: [data],
    mapComponent: "tract",
    extraMapProps: {
      showLatLng: false,
    },
  });

  return (
    <div className="flex justify-between gap-10 bg-[#F3F3F3] rounded-xl p-5">
      <div className="w-1/3 h-[200px]">{renderMap()}</div>
      <div className="flex-1 flex flex-col items-center">
        <div className="flex">
          <span className="text-lg font-bold mr-3">
            {data.report_year} Reporting Tract Data - Status:
          </span>
          <ReportStatus status={data.status || "N/A"} />
        </div>
        <div className="grid grid-cols-2 gap-y-4 mt-4">
          <div className="border-r border-gray-1">
            <div>Alias: {data.Alias}</div>
            <div>Tract Id: {data.Cert_ID}</div>
            <div>Phase Area: {data.Phase_Area}</div>
          </div>
          <div className="flex-1 pl-24">
            <div>Total Acres: {data.acres}</div>
            <div>Type: {data.cert_type}</div>
            <div>Water Source: {data.Water_Sources}</div>
            <div>Sub Area: {data.GWMA}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TractInfo;
