import React, { useEffect, useMemo, useState } from "react";
import { steps, inhibitorConfig, applicationConfig } from "../crop/configs";
import request from "../../utils/fetch";
import toast from "react-hot-toast";
import { getCropSchema } from "../../utils/validation";
import { useFormik } from "formik";
import CropReportFormContent from "./CropReportFormContent";

const formatData = (values, totalAcres) => {
  return Object.entries(values).reduce((result, [key, value]) => {
    if (key.includes("Percent_")) {
      result[key.replace("Percent_", "")] = parseFloat(
        ((value * totalAcres) / 100).toFixed(2)
      );
    } else if (
      key.includes(applicationConfig.prefix) ||
      key.includes(inhibitorConfig.prefix)
    ) {
      const [prefix, index, property] = key.split("-");
      const parsedIndex = parseInt(index);
      const nitrateKeyData =
        prefix === inhibitorConfig.prefix
          ? "Nitrate_Inhibitors"
          : "Nitrogen_Applications";
      if (!result[nitrateKeyData]) {
        result[nitrateKeyData] = [];
      }
      if (!result[nitrateKeyData][parsedIndex - 1]) {
        result[nitrateKeyData][parsedIndex - 1] = {};
      }
      let valueToSave = value;
      const configs = key.includes(applicationConfig.prefix)
        ? applicationConfig
        : inhibitorConfig;
      (configs.fields || []).forEach((field) => {
        if (field.key === property) {
          if (field.number) {
            valueToSave = parseFloat(value);
          } else if (field.date) {
            valueToSave = new Date(value).toISOString();
          }
        }
      });
      result[nitrateKeyData][parsedIndex - 1][property] = valueToSave;
    } else if (key.includes("manureApplications")) {
      const [, manureDataKey] = key.split("-");
      if (!result.Manure_Applications) {
        result["Manure_Applications"] = [{}];
      }
      let valueToSave = value;
      if (!isNaN(value)) {
        valueToSave = parseFloat(value);
      } else if (manureDataKey === "date_applied") {
        valueToSave = new Date(value).toISOString();
      }
      result["Manure_Applications"][0][manureDataKey] = valueToSave;
    } else {
      let valueToSave = value;
      steps.forEach((step) => {
        (step.fields || []).forEach((field) => {
          if (field.key === key) {
            if (field.number) {
              valueToSave = parseFloat(value);
            } else if (field.date) {
              valueToSave = new Date(value).toISOString();
            }
          }
        });
      });
      result[key] = valueToSave;
    }
    return result;
  }, {});
};

const getInitialValues = (data) => {
  return Object.entries(data).reduce((result, [key, value]) => {
    if (key === "Nitrogen_Applications") {
      (value || []).forEach((item, index) => {
        Object.entries(item).forEach(([itemKey, itemValue]) => {
          result[`${applicationConfig.prefix}-${index + 1}-${itemKey}`] =
            itemValue;
          if (itemKey === "n_percent" && itemValue) {
            result[`${applicationConfig.prefix}-${index + 1}-advanced`] = true;
          }
        });
      });
    } else if (key === "Manure_Applications") {
      Object.entries(value?.[0] || {}).forEach(([itemKey, itemValue]) => {
        result[`manureApplications-${itemKey}`] = itemValue;
      });
    } else {
      result[key] = value;
    }
    return result;
  }, {});
};

const CropReportForm = ({
  tractAcres,
  reportId,
  refreshData,
  initialValues,
  activeReport,
  setActiveReport,
  prevCropManure,
  setReportStep,
  activeReportStep,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState();
  const [numberApp, setNumberApp] = useState(1);
  const [numberInhibitor, setNumberInhibitor] = useState(1);
  const currentStep = steps[activeStep];

  useEffect(() => {
    if (initialValues?.Nitrogen_Applications?.length) {
      setNumberApp(initialValues?.Nitrogen_Applications?.length);
    }
  }, [initialValues?.Nitrogen_Applications?.length]);

  function nextStepWithoutValidation(e) {
    if (activeStep === 0) {
      setActiveStep(3);
    } else if (activeStep === 4) {
      setReportStep(activeReportStep + 1);
    } else {
      setActiveStep(4);
    }
  }

  function nextStep(e) {
    e?.preventDefault();
    let isValid = true;
    if (activeStep === 0) {
      for (let i = 0; i < 3; i++) {
        const step = steps[i];
        if (step.validation) {
          const stepValid = step.validation(
            formik,
            tractAcres,
            numberApp,
            numberInhibitor
          );
          if (stepValid !== true) {
            isValid = stepValid;
            setError(isValid);
            return false;
          }
        }
      }
    } else if (currentStep.validation) {
      isValid = currentStep.validation(
        formik,
        tractAcres,
        numberApp,
        numberInhibitor
      );
    }

    if (isValid === true) {
      setError(null);
      return true;
    } else {
      setError(isValid);
      return false;
    }
  }

  const previousStep = (e) => {
    e.preventDefault();
    if (activeStep === 3) {
      setActiveStep(0);
    } else if (activeStep === 0) {
      setReportStep(activeReportStep - 1);
    } else {
      setActiveStep(activeStep - 1);
    }
    setError(null);
  };

  const formik = useFormik({
    initialValues: initialValues._id
      ? getInitialValues(initialValues)
      : initialValues,
    enableReinitialize: true,
    validationSchema: getCropSchema(tractAcres),
    onSubmit: async (values, { resetForm }) => {
      try {
        if (!nextStep()) {
          return;
        }
        setLoading(true);
        const payload = formatData(values, tractAcres);
        let result = null;
        if (activeStep === 3) {
          result = await request({
            method: "patch",
            url: `/reports/${reportId}`,
            data: {
              crop_section: [
                {
                  ...payload,
                  Nitrogen_Applications: [
                    {
                      ...payload.Nitrogen_Applications[0],
                      direct_nitrogen:
                        payload.Nitrogen_Applications[0].direct_nitrogen || 0,
                    },
                  ],
                },
              ],
            },
          });
          if (payload.Nitrogen_Applications?.length > 1) {
            for (let i = 1; i < payload.Nitrogen_Applications.length; i++) {
              await request({
                method: "patch",
                url: `/reports/${reportId}`,
                data: {
                  ...payload,
                  ...(payload._id ? {} : { _id: result?.data?.section_id }),
                  Nitrogen_Applications: [
                    {
                      ...payload.Nitrogen_Applications[i],
                      direct_nitrogen:
                        payload.Nitrogen_Applications[0].direct_nitrogen || 0,
                    },
                  ],
                },
              });
            }
          }
        } else {
          result = await request({
            method: "patch",
            url: `/reports/${reportId}`,
            data: {
              crop_section: [{ ...payload, Nitrogen_Applications: undefined }],
            },
          });
        }

        // if (!payload._id) {
        await refreshData();
        // }
        // if(activeStep === 0) {
        resetForm();
        // }
        setLoading(null);
        // nextStepWithoutValidation();
        // resetForm();
        // setActiveStep(0);
        // setNumberApp(1);
        // setNumberInhibitor(1);
        toast.success("Successfully updated crop report");
        if (result?.data?.section_id) {
          setActiveReport(result?.data?.section_id);
        }
      } catch (e) {
        toast.error(e.response?.data?.message || e.message);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <CropReportFormContent
      formik={formik}
      isLoading={isLoading}
      tractAcres={tractAcres}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      error={error}
      nextStep={nextStepWithoutValidation}
      previousStep={previousStep}
      currentStep={currentStep}
      numberApp={numberApp}
      setNumberApp={setNumberApp}
      numberInhibitor={numberInhibitor}
      setNumberInhibitor={setNumberInhibitor}
      prevCropManure={prevCropManure}
      isNew={activeReport === "new"}
    />
  );
};

const CropReport = ({
  tractAcres,
  tractId,
  reportId,
  data,
  refreshData,
  setReportStep,
  activeReportStep,
  reportYear
}) => {
  const [activeReport, setActiveReport] = useState("new");

  const getReportItemClassName = (id) => {
    return `${
      activeReport === id ? "bg-gray-1" : "bg-gray-f2"
    } rounded-xl px-7 py-2 cursor-pointer font-medium`;
  };

  const getCropAcres = (item) => {
    return (
      (item.Sprinkler_Acres || 0) +
      (item.Flood_Acres || 0) +
      (item.Other_Irr_Acres || 0)
    );
  };

  const availableAcres = useMemo(() => {
    return (
      tractAcres -
      (data || []).reduce(
        (acc, item) =>
          acc + getCropAcres(item),
        0
      )
    );
  }, [tractAcres, data]);

  const handleAddNewCrop = () => {
    if(availableAcres > 0) {
      setActiveReport("new");
    } else {
      toast.error("All Tract Acres Accounted For");
    }
  }

  useEffect(() => {
    if (availableAcres <= 0 && activeReport === "new") {
      setActiveReport(data?.[0]?._id);
    }
  }, [availableAcres, activeReport, data]);

  const form = useMemo(() => {
    return (
      <div>
        {data?.length
          ? data.map((item, index) => (
              <div
                className={`${activeReport === item._id ? "block" : "hidden"}`}
              >
                <CropReportForm
                  tractAcres={(availableAcres > 0 ? availableAcres : 0) + getCropAcres(item)}
                  reportId={reportId}
                  refreshData={refreshData}
                  initialValues={item}
                  activeReport={activeReport}
                  setActiveReport={setActiveReport}
                  activeReportStep={activeReportStep}
                  setReportStep={setReportStep}
                  prevCropManure={
                    index - 1 >= 0
                      ? data[index - 1]?.Manure_Applications?.[0]
                      : null
                  }
                />
              </div>
            ))
          : null}
        <div className={`${activeReport === "new" ? "block" : "hidden"}`}>
          <CropReportForm
            key={`new-${data?.length}`}
            tractAcres={availableAcres > 0 ? availableAcres : 0}
            reportId={reportId}
            refreshData={refreshData}
            activeReport={activeReport}
            setActiveReport={setActiveReport}
            activeReportStep={activeReportStep}
            setReportStep={setReportStep}
            prevCropManure={
              data?.length
                ? data[data.length - 1]?.Manure_Applications?.[0]
                : null
            }
            initialValues={{
              Year: reportYear || new Date().getFullYear(),
              Crop_Yield_Units: "bushels / ac",
            }}
          />
        </div>
      </div>
    );
  }, [
    data,
    activeReport,
    availableAcres,
    reportId,
    refreshData,
    activeReportStep,
    setReportStep,
    reportYear
  ]);

  return (
    <div>
      <div className="flex items-center justify-between mb-5">
        <div className="flex items-center">
          <span className="text-lg font-semibold">Tract Crops:</span>
          <div className="flex items-center gap-2 ml-10 flex-wrap">
            {data?.length
              ? data.map((item) => (
                  <span
                    className={getReportItemClassName(item._id)}
                    onClick={() => setActiveReport(item._id)}
                  >
                    {item.Crop_Planted}
                  </span>
                ))
              : null}
            <span
              className={getReportItemClassName("new")}
              onClick={handleAddNewCrop}
            >
              + New Crop
            </span>
          </div>
        </div>
        <span
          className="cursor-pointer underline pr-4"
          onClick={() => setReportStep(activeReportStep + 1)}
        >
          Skip {">>"}
        </span>
      </div>
      {form}
    </div>
  );
};

export default CropReport;
