import React, { useRef, useState } from "react";
import { steps } from "../soil/configs";
import { FormikProvider } from "formik";
import Button from "../common/Button";
import { twMerge } from "tailwind-merge";
import Input from "../common/form/Input";
import Toggle from "../common/Toggle";
import DatePicker from "../common/form/DatePicker";
import SoilLayer from "../soil/SoilLayer";
import SoilTestResult from "../soil/SoilTestResult";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import Select from "../common/form/Select";
import ConfirmModal from "../common/ConfirmModal";

const SoilReportFormContent = ({
  formik,
  isLoading,
  renderMap,
  initNumberLayer = 1,
  backToPrevReport,
  error,
  setError,
  activeReportStep,
  setReportStep
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [activeLayerStep, setActiveLayerStep] = useState(1);
  const [numberLayer, setNumberLayer] = useState(initNumberLayer);
  const [nextTestResultLayer, setNextTestResultLayer] = useState();
  const layerRef = useRef();

  const currentStep = steps[activeStep];

  const renderItem = (
    {
      label,
      key,
      suffix,
      bool,
      textarea,
      number,
      date,
      comment,
      elementClassName,
      soilLayer,
      dropdown,
      options,
    },
    index
  ) => {
    const labelToShow = label || key.split("_").join(" ");

    const inputComment = comment && (
      <div className="text-sm text-gray-500">{comment}</div>
    );

    if (bool) {
      return (
        <div
          key={`${key}_${index}`}
          className={elementClassName || "col-span-1"}
        >
          <Toggle
            key={key}
            value={formik.values[key]}
            label={labelToShow}
            onChange={(value) => formik.setFieldValue(key, value)}
          />
          {inputComment}
        </div>
      );
    }
    if (date) {
      return (
        <div
          key={`${key}_${index}`}
          className={elementClassName || "col-span-1"}
        >
          <DatePicker
            label={labelToShow}
            value={formik.values[key]}
            onChange={(e) => {
              formik.setFieldValue(key, e.target.value);
            }}
          />
        </div>
      );
    }
    if (soilLayer) {
      return (
        <SoilLayer
          key={`${key}_${index}`}
          formik={formik}
          numberLayer={numberLayer}
          setNumberLayer={setNumberLayer}
          error={error}
        />
      );
    }
    if (dropdown) {
      return (
        <Select
          id={key}
          name={key}
          label={labelToShow}
          extraOptions={options}
          onChange={(option) => {
            formik.setFieldValue(key, option);
          }}
          value={formik.values[key]}
        />
      );
    }
    return (
      <div key={`${key}_${index}`} className={elementClassName || "col-span-1"}>
        <Input
          name={key}
          label={labelToShow}
          textarea={textarea}
          suffix={suffix}
          type={number ? "number" : "text"}
          onChange={(e) => {
            formik.setFieldValue(
              key,
              number && e.target.value
                ? parseFloat(e.target.value)
                : e.target.value
            );
            formik.setFieldTouched(key);
          }}
        />
        {inputComment}
      </div>
    );
  };

  const nextStep = (e) => {
    e.preventDefault();
    let isValid = true;
    if (activeStep === 1) {
      for (let i = 1; i < 3; i++) {
        const step = steps[i];
        if (step.validation) {
          const stepValid = step.validation(formik.values, numberLayer);
          if (stepValid !== true) {
            isValid = stepValid;
            setError(isValid);
            return false;
          }
        }
      }
    } else if (currentStep.validation) {
      isValid = currentStep.validation(formik.values, numberLayer);
    }
    if (
      layerRef?.current &&
      currentStep.type === "test_result" &&
      !layerRef?.current.nextLayer()
    ) {
      isValid = "Please enter all required fields";
      setError(isValid);
    }
    if (isValid === true) {
      setActiveStep(activeStep === 1 ? activeStep + 2 : activeStep + 1);
      setError(null);
      if (steps[activeStep + 1].type === "test_result" && layerRef?.current) {
        layerRef?.current.initialValidation();
      }
    } else if (currentStep.type !== "test_result") {
      setError(isValid);
      formik.validateForm();
    }

    if(activeStep === steps.length - 2) {
        setReportStep(activeReportStep + 1);
    }
  };

  const previousStep = (e) => {
    e.preventDefault();
    if (activeStep === 0) {
      backToPrevReport();
    } else {
      setActiveStep(activeStep === 3 ? activeStep - 2 : activeStep - 1);
    }
    setError(null);
  };

  const handleSelectLayer = (index) => {
    const valid = layerRef.current.validateCurrentLayer();
    if (valid) {
      layerRef.current.selectLayer(index + 1);
      setTimeout(() => setActiveLayerStep(index + 1), 200);
    } else {
      setNextTestResultLayer(index + 1);
    }
  };

  const shouldSubmitForm = formik.dirty && activeStep !== 0;

  return (
    <div className="p-2 flex relative">
      <div className="w-full bg-white dark:bg-gray-600 rounded-lg">
        {activeStep === 1 && (
          <div className="text-lg font-bold">Add Soil Sample Information</div>
        )}
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <div className="p-4">
              {steps.map((step, stepIdx) => {
                if (step.type === "test_result") {
                  return (
                    <div
                      key={stepIdx}
                      className={activeStep === stepIdx ? "visible" : "hidden"}
                    >
                      <div className="flex items-center">
                        <span>Soil Layer Results:</span>
                        <div className="flex items-center gap-2 ml-5 flex-wrap">
                          {Array(numberLayer)
                            .fill("")
                            .map((_, layerIndex) => (
                              <span
                                className={`text-sm rounded-xl px-7 py-2 cursor-pointer font-medium ${
                                  activeLayerStep === layerIndex + 1
                                    ? "bg-gray-1"
                                    : "bg-gray-f2"
                                }`}
                                onClick={() => handleSelectLayer(layerIndex)}
                              >
                                Layer {layerIndex + 1} (
                                {
                                  formik.values[
                                    `soil_layer_${layerIndex + 1}_top_depth`
                                  ]
                                }{" "}
                                to{" "}
                                {
                                  formik.values[
                                    `soil_layer_${layerIndex + 1}_bottom_depth`
                                  ]
                                }
                                )
                              </span>
                            ))}
                        </div>
                      </div>
                      <div className={twMerge("mt-5", step.className)}>
                        <SoilTestResult
                          ref={layerRef}
                          key={stepIdx}
                          formik={formik}
                          fields={step.fields}
                          numberLayer={numberLayer}
                          showNextLayer={false}
                        />
                      </div>
                    </div>
                  );
                } else if (step.type === "map") {
                  return (
                    <div
                      key={stepIdx}
                      className={activeStep === stepIdx ? "visible" : "hidden"}
                    >
                      <div className="text-lg">Soil Sample Data</div>
                      <div>
                        Click on a soil sample location in the map to start the
                        process or press “Add Sample Location” to add a new
                        location for your soil sample.
                      </div>
                      <div className="w-full h-[472px] mt-5">{renderMap()}</div>
                    </div>
                  );
                } else if (step.type === "submit") {
                  return (
                    <div
                      key={stepIdx}
                      className={twMerge(
                        "flex flex-col justify-center items-center",
                        activeStep === stepIdx ? "visible" : "hidden",
                        step.className
                      )}
                    >
                      <Button
                        type="submit"
                        disabled={isLoading}
                        loading={isLoading}
                        className="my-5 w-fit float-right"
                      >
                        Submit Sample
                      </Button>
                    </div>
                  );
                }
                return (
                  <div
                    key={stepIdx}
                    className={twMerge(
                      "grid grid-cols-1 md:grid-cols-2 gap-5",
                      activeStep === stepIdx ||
                        (activeStep === 1 && stepIdx === 2)
                        ? "visible mt-5"
                        : "hidden",
                      step.className
                    )}
                  >
                    {(step.fields || []).map((item, index) =>
                      renderItem(item, index)
                    )}
                  </div>
                );
              })}
              <div className="flex items-center justify-between mt-5">
                <ChevronLeftIcon
                  width={40}
                  height={40}
                  className="cursor-pointer"
                  onClick={previousStep}
                />
                <Button
                  type={shouldSubmitForm ? "submit" : "button"}
                  className="w-fit z-100"
                  onClick={shouldSubmitForm ? undefined : nextStep}
                >
                  {shouldSubmitForm ? "Save" : "Next"}
                </Button>
              </div>
              <div
                className={twMerge(
                  "mt-2 text-red-500 invisible opacity-0 text-end",
                  error ? "visible opacity-100 content_active" : "h-0"
                )}
              >
                {error}
              </div>
            </div>
          </form>
        </FormikProvider>
      </div>
      <ConfirmModal
        isOpen={!!nextTestResultLayer}
        toggle={() => {
          setNextTestResultLayer(null);
        }}
        message="The current layer has invalid fields. Do you still want to continue to the other layer?"
        onConfirm={() => {
          layerRef.current.selectLayer(nextTestResultLayer);
          setTimeout(() => {
            setActiveLayerStep(nextTestResultLayer);
            setNextTestResultLayer(null);
          }, 200);
        }}
        warning={true}
      />
    </div>
  );
};

export default SoilReportFormContent;