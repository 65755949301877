import React, { useCallback } from "react";
import Input from "../common/form/Input";
import Select from "../common/form/Select";
import Toggle from "../common/Toggle";
import { FormikProvider } from "formik";
import { twMerge } from "tailwind-merge";
import Button from "../common/Button";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { steps, applicationConfig } from "../crop/configs";
import DatePicker from "../common/form/DatePicker";
import CropNitrateApplicationNew from "../crop/CropNitrateApplicationNew";
import { calculateTotalAcres, isValidDropdownValue } from "../crop/CropAddNew";

const CropReportFormContent = ({
  formik,
  isLoading,
  tractAcres,
  activeStep,
  error,
  nextStep,
  previousStep,
  numberApp,
  setNumberApp,
  prevCropManure,
  isNew,
}) => {
  const getStepTitle = useCallback(
    (step) => {
      const { values } = formik;
      let titleToShow = step.title
        .replace("$$crop", values.Crop_Planted || "0")
        .replace(
          "$$totalAcres",
          calculateTotalAcres(values, tractAcres) || "0"
        );
      return titleToShow;
    },
    [formik, tractAcres]
  );

  const handleAddManureAppFromPreviousCrop = useCallback(() => {
    if (prevCropManure) {
      Object.entries(prevCropManure).forEach(([key, value]) => {
        formik.setFieldValue(`manureApplications-${key}`, value);
      });
    }
  }, [formik, prevCropManure]);

  const renderAcresInput = useCallback(
    (key) => {
      return (
        <div className="flex items-center justify-between gap-x-3 mt-2">
          <div className="flex-1">
            <Select
              id={`Percent_${key}`}
              name={`Percent_${key}`}
              className="z-100"
              extraOptions={['', 0, 10, 25, 33, 50, 67, 75, 90, 100]}
              onChange={(option) => {
                formik.setFieldValue(`Percent_${key}`, option);
                formik.setFieldValue(key, undefined);
              }}
              isDisabled={formik.values[key]}
              value={formik.values[`Percent_${key}`]}
              getOptionLabel={(option) => {
                if(option.value === '') {
                    return(<span>Please select</span>);
                }
                return isValidDropdownValue(option.label) ? (
                  <span className="text-black dark:text-white">
                    {option.label}%
                    {isValidDropdownValue(option.value) && (
                      <span className="text-gray-500 ml-3">
                        {((option.value * tractAcres) / 100).toFixed(2)} acres
                      </span>
                    )}
                  </span>
                ) : (
                  ""
                );
              }}
            />
          </div>
          <div>OR</div>
          <div className="flex-1">
            <Input
              name={key}
              className="mt-0"
              onChange={(e) => {
                formik.setFieldValue(key, e.target.value);
                formik.setFieldTouched(key);
                formik.setFieldValue(`Percent_${key}`, undefined);
              }}
              disabled={
                formik.values[`Percent_${key}`] ||
                formik.values[`Percent_${key}`] === 0
              }
            />
          </div>
        </div>
      );
    },
    [formik, tractAcres]
  );

  const renderItem = (
    {
      label,
      key,
      suffix,
      bool,
      textarea,
      number,
      dropdown,
      options,
      comment,
      elementClassName,
      percent,
      hasPercent,
      date,
      disabled,
    },
    index,
    isStepActive
  ) => {
    const labelToShow = label || key.split("_").join(" ");

    const inputComment = comment && (
      <div className="text-sm text-gray-500">{comment}</div>
    );

    if (bool) {
      return (
        <div
          key={`${key}_${index}`}
          className={elementClassName || "col-span-1"}
        >
          <Toggle
            key={key}
            value={formik.values[key]}
            label={labelToShow}
            onChange={(value) => formik.setFieldValue(key, value)}
          />
          {inputComment}
        </div>
      );
    }
    if (date) {
      return (
        <div
          key={`${key}_${index}`}
          className={elementClassName || "col-span-1"}
        >
          <DatePicker
            name={key}
            label={label}
            inputClassName="h-[38px]"
            value={formik.values[key]}
            onChange={(e) => formik.setFieldValue(key, e.target.value)}
          />
        </div>
      );
    }
    if (dropdown) {
      return (
        <React.Fragment key={`${key}_${index}`}>
          <div className={elementClassName || "col-span-1"}>
            <Select
              id={key}
              name={key}
              label={labelToShow}
              extraOptions={options}
              onChange={(option) => {
                formik.setFieldValue(key, option);
              }}
              value={formik.values[key]}
            />
            {formik.errors[key] ? (
              <div className="text-xs text-red-600 mt-1">{formik.errors[key]}</div>
            ) : null}
            {inputComment}
          </div>
        </React.Fragment>
      );
    }
    return (
      <div key={`${key}_${index}`} className={elementClassName || "col-span-1"}>
        <Input
          name={key}
          label={labelToShow}
          textarea={textarea}
          suffix={suffix}
          type={number ? "number" : "text"}
          onChange={(e) => {
            formik.setFieldValue(key, e.target.value);
            formik.setFieldTouched(key);
            if (hasPercent) {
              formik.setFieldValue(`Percent_${key}`, undefined);
            }
          }}
          disabled={
            disabled ||
            formik.values[`Percent_${key}`] ||
            formik.values[`Percent_${key}`] === 0 ||
            (key === "Corn_Yield_Goal" && formik.values.Crop_Planted !== "Corn")
          }
        />
        {inputComment}
      </div>
    );
  };

  const shouldSubmitForm = (formik.dirty || isNew);

  return (
    <div className="w-full bg-white dark:bg-gray-600 rounded-lg">
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className="p-4">
            {steps.map((step, stepIdx) => {
              if (step.type === "submit") {
                return null;
              }
              if (step.type === "nitrate_application") {
                return (
                  <div
                    key={stepIdx}
                    className={twMerge(
                      "w-full",
                      step.className,
                      activeStep === stepIdx ? "visible" : "hidden"
                    )}
                  >
                    {/* <div className="text-lg mb-5">Nitrogen Applications</div> */}
                    <CropNitrateApplicationNew
                      formik={formik}
                      error={error}
                      config={applicationConfig}
                      numberApp={numberApp}
                      setNumberApp={setNumberApp}
                      expandable={false}
                    />
                  </div>
                );
              } else if (stepIdx === 1) {
                return (
                  <div
                    key={stepIdx}
                    className={twMerge(
                      "py-5 border-b",
                      (activeStep === 0 && stepIdx <= 2) ||
                        activeStep === stepIdx
                        ? "visible"
                        : "hidden"
                    )}
                  >
                    <div className="text-lg">{getStepTitle(step)}</div>
                    <div className="flex items-center justify-between gap-x-4 mt-3">
                      <div className="flex-1 bg-gray-f2 p-2">
                        <span>Sprinkler Acres</span>
                        {renderAcresInput("Sprinkler_Acres")}
                      </div>
                      <div className="flex-1 bg-gray-f2 p-2">
                        <span>Flood Acres</span>
                        {renderAcresInput("Flood_Acres")}
                      </div>
                      <div className="flex-1 bg-gray-f2 p-2">
                        <span>Other Acres</span>
                        {renderAcresInput("Other_Irr_Acres")}
                      </div>
                    </div>
                  </div>
                );
              }
              return (
                <div
                  key={stepIdx}
                  className={twMerge(
                    "py-5 border-b",
                    (activeStep === 0 && stepIdx <= 2) || activeStep === stepIdx
                      ? "visible"
                      : "hidden"
                  )}
                >
                  <div className="flex items-center justify-between">
                    <div className="text-lg">{getStepTitle(step)}</div>
                    {activeStep === 4 && prevCropManure ? (
                      <Button type="button" onClick={handleAddManureAppFromPreviousCrop}>
                        Add Sample Application from Previous Crop
                      </Button>
                    ) : null}
                  </div>
                  <div
                    className={twMerge(
                      "grid grid-cols-1 md:grid-cols-2 gap-5 mt-3",
                      step.className
                    )}
                  >
                    {step.fields.map((item, index) =>
                      renderItem(item, index, activeStep === stepIdx)
                    )}
                  </div>
                </div>
              );
            })}
            <div className="flex items-center justify-between mt-5">
              <ChevronLeftIcon
                width={40}
                height={40}
                className="cursor-pointer"
                onClick={previousStep}
              />
              <Button
                type={shouldSubmitForm ? "submit" : "button"}
                disabled={isLoading}
                loading={isLoading}
                className={`w-fit ${
                  activeStep < steps.length - 1 ? "" : "invisible"
                }`}
                onClick={shouldSubmitForm ? undefined : nextStep}
              >
                {shouldSubmitForm ? "Save" : "Next"}
              </Button>
            </div>
            <span
              className={twMerge(
                "mt-2 text-red-500 invisible opacity-0",
                error ? "visible opacity-100 content_active" : "h-0"
              )}
            >
              {error
                ? typeof error === "object"
                  ? error.isValid
                  : error
                : null}
            </span>
          </div>
        </form>
      </FormikProvider>
    </div>
  );
};

export default CropReportFormContent;
