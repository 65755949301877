import { FormikProvider, useFormik } from "formik";
import React from "react";
import Input from "./Input";
import DatePicker from "./DatePicker";
import Select from "./Select";
import Toggle from "../Toggle";
import { twMerge } from "tailwind-merge";
import NewPhoneInput from "../PhoneInput";

export const INPUT_TYPES = {
  INPUT: "input",
  CHECK_BOX: "toggle",
  DROP_DOWN: "select",
  DATE_TIME: "date_time",
  ASYNC_DROP_DOWN: "async_select",
  CHECK_BOXES: "check_boxes",
  PHONE_NUMBER: "phone_number",
  MULTI_SELECT: "multi_select",
};

const GenericForm = ({
  onSubmit,
  validationSchema,
  initialValues = {},
  fields,
  className,
  children,
  ...formProps
}) => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    ...formProps,
  });

  const renderInputField = (field, index) => {
    const {
      label,
      key,
      suffix,
      number,
      options,
      comment,
      elementClassName,
      type,
      otherProps,
    } = field;

    const labelToShow = label || key.split("_").join(" ");
    let showError = formik.errors[key];
    const inputComment = comment && (
      <div className="text-sm text-gray-500">{comment}</div>
    );

    let inputElement = null;
    switch (type) {
      case INPUT_TYPES.CHECK_BOX: {
        return (
          <Toggle
            key={key}
            value={formik.values[key]}
            label={labelToShow}
            onChange={(value) => formik.setFieldValue(key, value)}
            {...otherProps}
          />
        );
      }
      case INPUT_TYPES.DROP_DOWN: {
        inputElement = (
          <Select
            name={key}
            label={labelToShow}
            extraOptions={options}
            value={formik.values[key]}
            onChange={(option) => {
              formik.setFieldValue(key, option);
            }}
            className={showError ? "border-red-600" : ""}
            {...otherProps}
          />
        );
        break;
      }
      case INPUT_TYPES.DATE_TIME: {
        inputElement = (
          <DatePicker
            name={key}
            label={label}
            value={formik.values[key]}
            onChange={(e) => formik.setFieldValue(key, e.target.value)}
            {...otherProps}
          />
        );
        break;
      }
      case INPUT_TYPES.PHONE_NUMBER: {
        inputElement = (
          <NewPhoneInput
            label={labelToShow}
            name={key}
            value={formik.values[key]}
            onChange={(newVal) => formik.setFieldValue(key, newVal)}
            {...otherProps}
          />
        );
        showError = null;
        break;
      }
      default: {
        inputElement = (
          <Input
            name={key}
            label={labelToShow}
            suffix={suffix}
            type={number ? "number" : "text"}
            onChange={(e) => {
              formik.setFieldValue(
                key,
                number && e.target.value
                  ? parseFloat(e.target.value)
                  : e.target.value
              );
              formik.setFieldTouched(key);
            }}
            disabled={
              formik.values[`Percent_${key}`] ||
              formik.values[`Percent_${key}`] === 0 ||
              (key === "Corn_Yield_Goal" &&
                formik.values.Crop_Planted !== "Corn")
            }
            {...otherProps}
          />
        );
        showError = null;
      }
    }

    return (
      <div key={`${key}_${index}`} className={elementClassName || "col-span-1"}>
        {inputElement}
        {showError && <div className="text-red-600 text-xs">{showError}</div>}
        {inputComment}
      </div>
    );
  };

  return (
    <FormikProvider value={formik}>
      <form
        onSubmit={formik.handleSubmit}
        className={twMerge("grid grid-cols-1 md:grid-cols-2 gap-4", className)}
      >
        {(fields || []).map(renderInputField)}
        {typeof children === "function"
          ? children(formik, renderInputField)
          : children}
      </form>
    </FormikProvider>
  );
};

export default GenericForm;
