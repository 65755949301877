import { useMapRender } from "../../hooks/map/useMapRender";

const TractFilter = ({ data, filter, setFilter }) => {
  const { renderMap } = useMapRender({
    tracts: data,
    mapComponent: "tract",
    selectedItem: filter.tract,
    extraMapProps: {
      expandable: true,
      onIconClick: (type, id) => {
        if (filter.tract.includes(id)) {
          setFilter({
            ...filter,
            tract: filter.tract.filter((t) => t !== id),
          });
        } else {
          setFilter({
            ...filter,
            tract: [...filter.tract, id],
          });
        }
      },
    },
  });

  return renderMap();
};

export default TractFilter;
