import { useCallback, useMemo } from "react";
import convertToFeatureCollection from "../../utils/convertToFeatureCollection";
import GenericMap from "../../components/map/GenericMap";
import { getBoundBox } from "../../utils/turfHelpers";
import calculatePolygonViewport from "../../utils/calculatePolygonViewport";
import pointsWithinPolygon from "@turf/points-within-polygon";

export const useMapRender = (props) => {
  const {
    tracts,
    tractGeo,
    soils,
    wells,
    meters,
    chemigations,
    extraMapProps,
    selectedItem,
    mapComponent,
  } = props;

  const allPoints = useMemo(() => {
    const result = [];
    (tracts || [])
      .filter((c) => c?.geometry?.coordinates?.length)
      .forEach((c) => {
        if (c.geometry.type === "MultiPolygon") {
          c.geometry.coordinates
            .reduce((acc, [arr]) => [...acc, ...arr], [])
            .forEach((p) => result.push(p));
        } else (c.geometry.coordinates[0] || []).forEach((p) => result.push(p));
      });
    [wells, meters, chemigations, soils].forEach((t) =>
      (t || [])
        .filter((el) => el?.geometry?.coordinates)
        .forEach((el) => result.push(el.geometry.coordinates))
    );
    return result;
  }, [chemigations, meters, soils, tracts, wells]);

  const viewport = useMemo(
    () =>
      calculatePolygonViewport({
        type: "Polygon",
        coordinates: [allPoints],
      }) || {
        latitude: 41.5886072190021,
        longitude: -103.58719705449793,
        zoom: 12.950430835225765,
      },
    [allPoints]
  );

  const certGeo = tractGeo || convertToFeatureCollection(
    (tracts || []).map((item) => ({
      ...item,
      Alias: item.Alias || item.Cert_ID || item.tract_id,
    })),
    "Alias",
    ["Category"],
    "Tract"
  );

  const wellsGeo = convertToFeatureCollection(wells || [], "NRD_ID", [
    "Well_Type",
    "Mon_Active",
    "Well",
  ]);

  const flowmetersGeo = convertToFeatureCollection(
    meters || [],
    "FM_ID",
    [],
    "Flowmeter"
  );

  const chemigationsGeo = convertToFeatureCollection(
    chemigations || [],
    "Permit_No",
    [],
    "Chemigation"
  );

  const soilsGeo = convertToFeatureCollection(soils, "location_id");

  const selectedGeo = useMemo(() => {
    let selectedGeo = [];
    if (selectedItem) {
      (mapComponent === "tract"
        ? certGeo?.features
        : mapComponent === "well"
        ? wellsGeo?.features
        : mapComponent === "flowmeter"
        ? flowmetersGeo?.features
        : mapComponent === "soil"
        ? soilsGeo?.features
        : mapComponent === "chemigation"
        ? chemigationsGeo?.features
        : []
      ).forEach((item) => {
        if (Array.isArray(selectedItem)) {
          if (selectedItem.includes(item.properties.id)) {
            item.properties.isSelected = true;
            selectedGeo.push(item);
          } else {
            item.properties.isSelected = false;
          }
        } else {
          if (item.properties.id === selectedItem._id) {
            item.properties.isSelected = true;
            selectedGeo.push(item);
          } else {
            item.properties.isSelected = false;
          }
        }
      });

      if (mapComponent !== "tract" && selectedGeo) {
        (certGeo?.features || []).forEach((item) => {
          if (pointsWithinPolygon(selectedGeo, item)?.features?.length) {
            item.properties.isSelected = true;
          } else {
            item.properties.isSelected = false;
          }
        });
      }
    }
    return selectedGeo;
  }, [
    certGeo?.features,
    chemigationsGeo?.features,
    flowmetersGeo?.features,
    mapComponent,
    selectedItem,
    soilsGeo?.features,
    wellsGeo?.features,
  ]);
  const renderMap = useCallback(
    (customMapProps) => {
      let bboxMap;
      try {
        bboxMap = getBoundBox(
          selectedGeo?.length
            ? [...selectedGeo]
            : [
                ...certGeo.features,
                ...wellsGeo.features,
                ...flowmetersGeo.features,
                ...chemigationsGeo.features,
                ...soilsGeo.features,
              ]
        );
      } catch (e) {
        console.warn(e);
      }

      const shouldAddBBox = bboxMap.some(
        (i) => i !== Infinity && i !== -Infinity
      );

      return (
        <GenericMap
          certGeo={certGeo}
          wellsGeo={wellsGeo}
          flowmetersGeo={flowmetersGeo}
          chemigationsGeo={chemigationsGeo}
          soilsGeo={soilsGeo}
          viewportOverride={viewport}
          bboxMap={shouldAddBBox ? bboxMap : undefined}
          {...(customMapProps ? customMapProps : extraMapProps)}
        />
      );
    },
    [
      certGeo,
      chemigationsGeo,
      extraMapProps,
      flowmetersGeo,
      selectedGeo,
      soilsGeo,
      viewport,
      wellsGeo,
    ]
  );

  return {
    renderMap,
  };
};
