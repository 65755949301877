import React, { useCallback, useMemo, useState } from "react";
import ReportProgress from "./ReportProgress";
import useFetch from "../../hooks/useFetch";
import { useNavigate, useParams } from "react-router-dom";
import CropReport from "./CropReport";
import SoilReport from "./SoilReport";
import TractInfo from "./TractInfo";
import Button from "../common/Button";
import MeterReport from "./MeterReport";
import toast from "react-hot-toast";
import request from "../../utils/fetch";
import ReportConfirmation from "./ReportConfirmation";

const ReportDetail = () => {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  const { data, refresh } = useFetch(`/reports/${id}`);

  const handleSubmitReport = useCallback(async () => {
    try {
      setLoading(true);
      await request({ url: `/reports/${id}/submit`, method: "PUT" });
      toast.success("Report is submitted successfully");
      navigate("/reports");
    } catch (e) {
      toast.error(e.response?.data?.message || e.message);
    } finally {
      setLoading(false);
    }
  }, [id, navigate]);

  const content = useMemo(() => {
    if (!data) return null;
    if (step === 0) {
      return (
        <div>
          <div className="mt-10 flex flex-col items-center">
            <div>
              Confirm the Tract Information above is the tract you are
              submitting the report for.
            </div>
            <Button
              className="w-fit mt-5"
              onClick={() => setStep(step + 1)}
              disabled={loading}
              loading={loading}
            >
              Confirm
            </Button>
          </div>
        </div>
      );
    } else if (step === 2) {
      return (
        <CropReport
          tractAcres={data?.tract_details?.acres || 0}
          tractId={data?.tract_id}
          reportId={id}
          data={data.crop_section}
          refreshData={refresh}
          setReportStep={setStep}
          activeReportStep={step}
          reportYear={data?.report_year}
        />
      );
    } else if (step === 1) {
      return (
        <MeterReport
          tractId={data?.tract_id}
          data={data.flowmeter_section}
          reportId={id}
          refreshData={refresh}
          setReportStep={setStep}
          activeReportStep={step}
        />
      );
    } else if (step === 3) {
      return (
        <SoilReport
          tractAcres={data?.tract_details?.acres || 0}
          tract={data?.tract_details}
          reportId={id}
          data={data.soil_section?.[0]}
          refreshData={refresh}
          setReportStep={setStep}
          activeReportStep={step}
          reportYear={data?.report_year}
        />
      );
    } else if (step === 4) {
      return (
        <ReportConfirmation
          data={data}
          step={step}
          setStep={setStep}
          handleSubmitReport={handleSubmitReport}
        />
      );
    }
    return null;
  }, [data, step, loading, id, refresh, handleSubmitReport]);

  return (
    <div className="w-full min-h-screen">
      <div className="rounded-lg bg-white dark:bg-gray-900 w-full p-5 pb-10">
        <div className="border-b-2 border-b-zinc-200 pb-4">
          <h2 className="font-bold text-2xl dark:text-white">Reports</h2>
          <p className="text-gray-1 mt-2">
            Track, manage, and create tract reports
          </p>
        </div>
        <div className="mt-12 flex justify-center">
          <ReportProgress step={step} setStep={setStep} />
        </div>
        <div className="mt-10 border border-gray-1 rounded-lg p-3">
          {data ? (
            <TractInfo
              data={{
                ...data?.tract_details,
                status: data?.status,
                report_year: data?.report_year,
              }}
            />
          ) : null}
          <div className="mt-10">{content}</div>
        </div>
      </div>
    </div>
  );
};

export default ReportDetail;
