import React, { useEffect, useMemo, useState } from "react";
import useFetch from "../../hooks/useFetch";
import Button from "../common/Button";
import toast from "react-hot-toast";
import request from "../../utils/fetch";
import Input from "../common/form/Input";
import { FormikProvider, useFormik } from "formik";
import DatePicker from "../common/form/DatePicker";

const MeterForm = ({ data, handleSubmit, loading, nextReportStep }) => {
  const formik = useFormik({
    initialValues: data,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <div className="p-10">
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <DatePicker
              name="Date"
              label="Date"
              value={formik.values.Date}
              onChange={(e) => formik.setFieldValue("Date", e.target.value)}
            />
            <div>
              <Input required name="Reading" label="Reading" type="number" />
              <div className="text-sm text-gray-500">
                Enter Reading from flowmeter face, don’t include decimal point
              </div>
            </div>
          </div>
          <Input name="Note" label="Note" textarea />
          <hr className="my-10" />
          <Button
            type={formik.dirty ? "submit" : "button"}
            disabled={loading}
            className="float-right"
            onClick={formik.dirty ? undefined : nextReportStep}
          >
            {formik.dirty ? "Save" : "Next"}
          </Button>
        </form>
      </FormikProvider>
    </div>
  );
};

const MeterReport = ({
  tractId,
  data,
  reportId,
  refreshData,
  setReportStep,
  activeReportStep,
}) => {
  const [loading, setLoading] = useState(false);
  const [activeMeter, setActiveMeter] = useState();
  const [meters, setMeters] = useState([]);

  const { data: metersData } = useFetch(`/tracts/${tractId}/meters`);

  useEffect(() => {
    if (metersData?.meters?.length) {
      const combinedData = metersData.meters.reduce((acc, item) => {
        const existFm = (data || []).find((i) => i.fm_id === item._id);
        acc.push({
          label: item.alias || item.Serial_num || item.FM_ID,
          fm_id: item._id,
          Date: existFm?.Date?.includes("0001")
            ? new Date().toISOString()
            : existFm?.Date,
          Reading: existFm?.Reading,
          Note: existFm?.Note,
        });
        return acc;
      }, []);
      setMeters(combinedData);
      setActiveMeter(combinedData[0]);
    }
  }, [data, metersData]);

  const getReportItemClassName = (id) => {
    return `${
      activeMeter?.fm_id === id ? "bg-gray-1" : "bg-gray-f2"
    } rounded-xl px-7 py-2 cursor-pointer font-medium`;
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);
      await request({
        method: "PATCH",
        url: `/reports/${reportId}`,
        data: {
          flowmeter_section: [
            {
              ...values,
              Date: values.Date
                ? new Date(values.Date).toISOString()
                : undefined,
              label: undefined,
            },
          ],
        },
      });
      toast.success("Successfully updated meter report");
      await refreshData();
      resetForm();
    } catch (e) {
      toast.error(e.response?.data?.message || e.message);
    } finally {
      setLoading(false);
    }
  };

  const nextReportStep = () => {
    setReportStep(activeReportStep + 1);
  };

  return (
    <div className="w-full flex flex-col">
      <div className="flex items-center">
        <div className="text-lg font-semibold">Tract Flowmeter:</div>
        <div className="flex items-center gap-2 ml-10 flex-wrap">
          {meters.map((item) => (
            <span
              className={getReportItemClassName(item.fm_id)}
              onClick={() => setActiveMeter(item)}
            >
              {item.label}
            </span>
          ))}
        </div>
      </div>
      <div>
        {meters.length ? (
          meters.map((item) => (
            <div className={`${activeMeter.fm_id === item.fm_id ? "block" : "hidden"}`}>
              <MeterForm
                data={item}
                handleSubmit={handleSubmit}
                loading={loading}
                nextReportStep={nextReportStep}
              />
            </div>
          ))
        ) : (
          <Button className="float-right" onClick={nextReportStep}>
            Next
          </Button>
        )}
      </div>
    </div>
  );
};

export default MeterReport;
