import React, { useEffect, useState } from "react";
import Button from "../common/Button";
import NewSoilLocation from "../soil/NewSoilLocation";

const SoilLocationButton = ({ refreshSoilData, hasSoil, certGeo }) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!hasSoil) {
      setShowModal(true);
    }
  }, [hasSoil]);

  return (
    <>
      <Button
        className="absolute top-3 right-14 p-2 cursor-pointer border-0"
        priority="secondary"
        onClick={(e) => {
          e.preventDefault();
          setShowModal(true);
        }}
      >
        Add Sample Location
      </Button>
      <NewSoilLocation
        open={showModal}
        setIsOpen={setShowModal}
        refreshData={refreshSoilData}
        certGeo={certGeo}
      />
    </>
  );
};

export default SoilLocationButton;