import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { PrinterIcon } from "@heroicons/react/24/solid";
import React from "react";
import moment from "moment";
import Button from "../common/Button";
import toDataUrl from "../../utils/toDataUrl";
import toast from "react-hot-toast";

const ReportConfirmation = ({ handleSubmitReport, step, setStep, data }) => {
  const handlePrint = async () => {
    try {
      const [logo, { default: createReport }] = await Promise.all([
        toDataUrl("/PC_Horz_Color.png"),
        import("../../reports/generate/reportSummary"),
      ]);

      await createReport({
        data,
        logo,
      });
    } catch (e) {
      toast.error(e.response?.data?.error_message || e.message);
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="text-lg">Summary</div>
        <PrinterIcon
          width={24}
          height={24}
          className="cursor-pointer"
          onClick={handlePrint}
        />
      </div>
      <div className="px-3">
        {data?.flowmeter_section?.length ? (
          <div className="py-4 border-b border-b-gray-1">
            {data?.flowmeter_section.map((meter) => (
              <span className="flex gap-x-4">
                <span>Flowmeter: </span>
                <span>Reading: {meter.Reading}</span>
                {meter.Date ? (
                  <span>{moment(meter.Date).utc().format("MM/DD/YYYY")}</span>
                ) : null}
                <span>Note: {meter.Note}</span>
              </span>
            ))}
          </div>
        ) : null}
        {(data?.crop_section || []).map((crop) => (
          <div className="py-4 border-b border-b-gray-1">
            <div className="text-lg">Crop Planted: {crop.Crop_Planted}</div>
            <div className="px-3 mt-3">
              <span className="flex items-center flex-wrap gap-x-4 gap-y-2">
                {crop.Crop_Yield ? (
                  <span>Crop Yield: {crop.Crop_Yield}</span>
                ) : null}
                {crop.Corn_Yield_Goal ? (
                  <span>Corn Yield Goal: {crop.Corn_Yield_Goal}</span>
                ) : null}
                {crop.Sprinkler_Acres ? (
                  <span>Sprinkler Acres: {crop.Sprinkler_Acres}</span>
                ) : null}
                {crop.Flood_Acres ? (
                  <span>Flood Acres: {crop.Flood_Acres}</span>
                ) : null}
                {crop.Other_Irr_Acres ? (
                  <span>Other Acres: {crop.Other_Irr_Acres}</span>
                ) : null}
                {crop.Irrigation_Water_Applied ? (
                  <span>
                    Irrigation Water Applied : {crop.Irrigation_Water_Applied}
                  </span>
                ) : null}
                {crop.Water_Nitrate ? (
                  <span>Water Nitrate: {crop.Water_Nitrate}</span>
                ) : null}
                {crop.Tillage_Practice ? (
                  <span>Tillage Practice: {crop.Tillage_Practice}</span>
                ) : null}
              </span>
              {crop?.Nitrogen_Applications?.length ? (
                <div className="mt-3">
                  <div className="underline">Nitrogen Applications</div>
                  {crop.Nitrogen_Applications.map((nitrogen, index) => (
                    <span className="flex flex-wrap gap-x-4 gap-y-2 mt-2">
                      <span>
                        Nitrogen Application {index + 1} -{" "}
                        {moment(nitrogen.date_applied)
                          .utc()
                          .format("MM/DD/YYYY")}
                      </span>
                      <span>Emergent Type: {nitrogen.emergent_type}</span>
                      <span>
                        Nitrogen Applied: {nitrogen.direct_nitrogen} lbs/ac
                      </span>
                      <span>Inhibitor Type: {nitrogen.inhibitor_type}</span>
                    </span>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        ))}
        {data.soil_section?.length ? (
          <div className="mt-4">
            <div className="text-lg">
              Soil Sample: {data.soil_section[0].sample_id}
            </div>
            <div className="px-3 mt-3">
              <span className="flex items-center flex-wrap gap-x-4 gap-y-2">
                <span>
                  Date Sample Taken:{" "}
                  {moment(data.soil_section[0].sample_date)
                    .utc()
                    .format("MM/DD/YYYY")}
                </span>
                <span>Lab Name: {data.soil_section[0].lab_name}</span>
                <span>
                  Date on Lab Report:{" "}
                  {moment(data.soil_section[0].lab_date)
                    .utc()
                    .format("MM/DD/YYYY")}
                </span>
                <span>
                  Acres Represented: {data.soil_section[0].acres_represented}
                </span>
                <span>Soil Texture: {data.soil_section[0].soil_texture}</span>
              </span>
              {(() => {
                let numLayer = 1;
                for (const key in data.soil_section[0]) {
                  const match = key.match(/^soil_layer_(\d+)_/);
                  if (match) {
                    const layerNumber = parseInt(match[1], 10);
                    if (layerNumber > numLayer) {
                      numLayer = layerNumber;
                    }
                  }
                }
                return Array(numLayer)
                  .fill("")
                  .map((_, index) => (
                    <div className="mt-3">
                      <div className="underline">
                        Layer {index + 1} -{" "}
                        {
                          data.soil_section[0][
                            `soil_layer_${index + 1}_top_depth`
                          ]
                        }{" "}
                        to{" "}
                        {
                          data.soil_section[0][
                            `soil_layer_${index + 1}_bottom_depth`
                          ]
                        }
                      </div>
                      <span className="px-3 mt-3 flex flex-wrap gap-x-4 gap-y-2">
                        <span>
                          Organic Matter:{" "}
                          {
                            data.soil_section[0][
                              `soil_layer_${index + 1}_organic_matter`
                            ]
                          }
                          %
                        </span>
                        <span>
                          Nitrogen:{" "}
                          {
                            data.soil_section[0][
                              `soil_layer_${index + 1}_nitrate`
                            ]
                          }{" "}
                          ppm
                        </span>
                        <span>
                          Phosporus:{" "}
                          {
                            data.soil_section[0][
                              `soil_layer_${index + 1}_phosphate`
                            ]
                          }{" "}
                          ppm
                        </span>
                        <span>
                          K (Potassium):{" "}
                          {
                            data.soil_section[0][
                              `soil_layer_${index + 1}_potassium`
                            ]
                          }{" "}
                          ppm
                        </span>
                      </span>
                    </div>
                  ));
              })()}
            </div>
          </div>
        ) : null}
        <div className="mt-10 flex items-center justify-between">
          <ChevronLeftIcon
            width={40}
            height={40}
            className="cursor-pointer"
            onClick={() => setStep(step - 1)}
          />
          <Button onClick={handleSubmitReport}>Submit</Button>
        </div>
      </div>
    </div>
  );
};

export default ReportConfirmation;
