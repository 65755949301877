import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { SoilSchema } from "../../utils/validation";
import request from "../../utils/fetch";
import useFetch, { useLazyFetch } from "../../hooks/useFetch";
import SoilReportFormContent from "./SoilReportFormContent";
import { useMapRender } from "../../hooks/map/useMapRender";
import pointsWithinPolygon from "@turf/points-within-polygon";
import convertToFeatureCollection from "../../utils/convertToFeatureCollection";

const SoilReport = ({
  reportId,
  tractAcres,
  tract,
  data: soilReportData,
  refreshData,
  activeReportStep,
  setReportStep,
  reportYear,
}) => {
  const [isLoading, setLoading] = useState(true);
  const [selectedSoil, setSelectedSoil] = useState();
  const [error, setError] = useState();

  const { data, refresh } = useFetch("/soils");

  const formik = useFormik({
    initialValues: soilReportData
      ? soilReportData
      : {
          sample_date: new Date().toISOString(),
          lab_date: new Date().toISOString(),
          soil_layer_1_top_depth: 0,
          composite_sample: false,
          sample_id: `Sample ${reportYear || new Date().getFullYear()}`,
          acres_represented: tractAcres || 0,
        },
    validationSchema: SoilSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        await request({
          method: "patch",
          url: `/reports/${reportId}`,
          data: {
            soil_section: [values],
          },
        });
        toast.success("Successfully added soil sample");
        resetForm();
        refreshData();
        setError(null)
        // setReportStep(activeReportStep + 1);
      } catch (e) {
        toast.error(e.response?.data?.message || e.message);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleSoilClick = useCallback(
    (type, id) => {
      const newSelectedSoil = (data?.soils || []).find(
        (item) => item._id === id
      );
      if (newSelectedSoil) {
        setSelectedSoil(newSelectedSoil);
      }
    },
    [data?.soils]
  );

  const filteredSoils = useMemo(() => {
    if (!data?.soils || !tract) return [];
    const soilsGeo = convertToFeatureCollection(data.soils, "location_id");
    const tractPolygon = convertToFeatureCollection(
      [tract].map((item) => ({
        ...item,
        Alias: item.Alias || item.Cert_ID || item.tract_id,
      })),
      "Alias",
      ["Category"],
      "Tract"
    );
    const filteredGeo = soilsGeo.features.filter((soil) => pointsWithinPolygon(soil, tractPolygon.features[0])?.features.length);

    return data.soils.filter((soil) => filteredGeo.find((geo) => geo.properties.id === soil._id));
  }, [data?.soils, tract]);

  const { renderMap } = useMapRender({
    tracts: [tract],
    soils: filteredSoils,
    selectedItem: selectedSoil,
    mapComponent: "soil",
    extraMapProps: {
      iconSize: 1.5,
      onIconClick: handleSoilClick,
      showSoilLocationButton: true,
      refreshSoilData: refresh,
      bboxPadding: 100,
      fetchedSoils: !!data?.soils,
      bboxMaxZoom: 15.5,
    },
  });

  const initNumberLayer = useMemo(() => {
    let numLayer = 1;
    if (soilReportData) {
      for (const key in soilReportData) {
        const match = key.match(/^soil_layer_(\d+)_/);
        if (match) {
          const layerNumber = parseInt(match[1], 10);
          if (layerNumber > numLayer) {
            numLayer = layerNumber;
          }
        }
      }
    }
    return numLayer;
  }, [soilReportData]);

  return (
    <SoilReportFormContent
      formik={formik}
      isLoading={isLoading}
      renderMap={renderMap}
      initNumberLayer={initNumberLayer}
      backToPrevReport={() => setReportStep(activeReportStep - 1)}
      error={error}
      setError={setError}
      setReportStep={setReportStep}
      activeReportStep={activeReportStep}
    />
  );
};

export default SoilReport;
