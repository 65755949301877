import React, { useState } from "react";
import Modal from "../common/Modal";
import toast from "react-hot-toast";
import request from "../../utils/fetch";
import Button from "../common/Button";
import Toggle from "../common/Toggle";
import Input from "../common/form/Input";
import { useMapRender } from "../../hooks/map/useMapRender";
import { twMerge } from "tailwind-merge";

const fields = [
  {
    key: "soil_location_id",
    label: "Soil Location Name",
  },
  {
    key: "irrigated",
    label: "Irrigated",
    bool: true,
  },
  {
    key: "note",
    textarea: true,
    label: "Note",
    elementClassName: "col-span-2",
  },
];

const TextInput = ({
  label,
  name,
  required,
  textarea,
  type,
  onChange,
  value,
}) => {
  const inputClassName =
    "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 dark:bg-neutral-700 dark:text-neutral-200";
  return (
    <>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
      >
        {label}
      </label>
      <div className="mt-2">
        {textarea ? (
          <textarea
            id={name}
            name={name}
            required={required}
            onChange={onChange}
            value={value}
            rows={3}
            className={inputClassName}
          />
        ) : (
          <input
            id={name}
            name={name}
            autoComplete={name}
            onChange={onChange}
            value={value}
            type={type}
            className={`${inputClassName} dark:disabled:bg-neutral-600 disabled:bg-gray-f2`}
          />
        )}
      </div>
    </>
  );
};

const NewSoilLocationModalContent = ({
  formState,
  setFormState,
  isLoading,
  certGeo,
  mapSelectionData,
  setMapSelectionData,
  handleSubmit,
}) => {
  const newSoil = mapSelectionData?.lng
    ? {
        geometry: {
          type: "Point",
          coordinates: [mapSelectionData.lng, mapSelectionData.lat],
        },
        _id: "soil_location",
      }
    : undefined;
  const { renderMap } = useMapRender({
    tractGeo: certGeo,
    soils: newSoil ? [newSoil] : [],
    mapComponent: "soil",
    selectedItem: newSoil ?? null,
    extraMapProps: {
      expandable: true,
      onIconClick: (type, id, location) => {
        setMapSelectionData({ id, ...location });
      },
    },
  });

  const handleChange = (key, value) => {
    setFormState((prev) => ({ ...prev, [key]: value }));
  };

  const renderItem = ({
    key,
    label,
    elementClassName,
    number,
    bool,
    textarea,
  }) => {
    if (bool) {
      return (
        <div
          key={key}
          className={`flex items-center justify-center ${
            elementClassName || "col-span-1"
          }`}
        >
          <Toggle
            key={key}
            value={formState[key]}
            label={label}
            onChange={(value) => handleChange(key, value)}
          />
        </div>
      );
    }
    return (
      <div key={key} className={elementClassName || "col-span-1"}>
        <TextInput
          name={key}
          label={label}
          type={number ? "number" : "text"}
          textarea={textarea}
          onChange={(e) => {
            handleChange(
              key,
              number && e.target.value
                ? parseFloat(e.target.value)
                : e.target.value
            );
          }}
        />
      </div>
    );
  };

  return (
    <div className="p-4">
      <div className="grid grid-cols-2 gap-x-5 gap-y-3">
        {fields.map(renderItem)}
      </div>
      <div className="w-4/6 aspect-video mt-3 mx-auto">
        <div className="text-sm font-medium leading-6 text-gray-900 mb-2 mx-auto">
          Select Soil Location
        </div>
        {renderMap()}
      </div>
      <div className="flex justify-end">
        <Button
          onClick={handleSubmit}
          className="w-fit mt-3"
          loading={isLoading}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

const NewSoilLocation = ({ open, setIsOpen, refreshData, certGeo }) => {
  const [isLoading, setLoading] = useState(false);
  const [mapSelectionData, setMapSelectionData] = useState({});
  const [formState, setFormState] = useState({});

  const handleSubmit = async () => {
    try {
      if (
        !mapSelectionData.id ||
        !mapSelectionData.lat ||
        !mapSelectionData.lng
      ) {
        toast.error("Please select soil location");
        return;
      }

      setLoading(true);
      await request({
        method: "post",
        url: "/soils/",
        data: {
          ...formState,
          associated_tract: mapSelectionData.id,
          latitude: parseFloat(mapSelectionData.lat),
          longitude: parseFloat(mapSelectionData.lng),
        },
      });
      if (refreshData) {
        refreshData();
      }
      toast.success("Successfully added crop");
      setLoading(null);
      setIsOpen(false);
      setMapSelectionData({});
      setFormState({});
    } catch (e) {
      toast.error(e.response?.data?.message || e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={open}
      close={() => {
        setIsOpen(false);
        setFormState({});
        setMapSelectionData({});
      }}
      title="New Soil Location"
      Content={NewSoilLocationModalContent}
      contentClassName="soilLocationModalContent"
      contentProps={{
        formState,
        setFormState,
        isLoading,
        certGeo,
        mapSelectionData,
        setMapSelectionData,
        handleSubmit,
      }}
    />
  );
};

export default NewSoilLocation;
